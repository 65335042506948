import React, { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import { Plural } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import cx from "classnames";
import ArrowUp from "img/icons/icon-up.svg";
import ChartPad from "img/chart-pad-v2.svg";
import ChartPadLight from "img/chart-pad-light.svg";
import {
  LONG,
  THEME_LIGHT,
  getCompletePosition,
  getMarketTokensInfo,
  getPageTitle,
  getTradePageTitle,
} from "lib/legacy";

import { getContract } from "config/contracts";

import ReaderMsp from "abis/ReaderMsp.json";

import Checkbox from "components/Checkbox/Checkbox";
import SwapBox from "components/ExchangeV2/SwapBox";
import ExchangeTVChart from "components/ExchangeV2/ExchangeTVChart";
import PositionsList from "components/ExchangeV2/PositionsList";

import Tab from "components/Tab/Tab";

import "./Exchange.css";
import "./Exchange.scss";
import "./trade_light.scss";
import { contractFetcher } from "lib/contracts";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { getTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import TradingView from "../../components/ExchangeV2/TradingView";
import ExchangeTVChartPad from "components/ExchangeV2/ExchangeTVChartPad";
import { useMediaQuery } from "react-responsive";
import ClosedPositionsList from "components/ExchangeV2/ClosedPositionsList";
import useMarketAssets from "hooks/useMarketAssets";
import TradeActivityList from "components/ExchangeV2/TradeActivityList";
import useAssetsPrices from "hooks/useAssetsPrices";

import useUserPosition from "hooks/useUserPosition";
import OrderListNew from "components/ExchangeV2/OrderListNew";
import useUserOrder from "hooks/useUserOrder";
import SEO from "components/Common/SEO";
import useUserTradeInfo from "hooks/useUserTradeInfo";
import useUserStats from "hooks/useUserStats";
import { formatAmount } from "lib/numbers";
import { FANTOM, OP } from "config/chains";
import { useHistory } from "react-router-dom";
import { useThemeContext } from "contexts/ThemeProvider";
import useRouteQuery from "lib/useRouteQuery";
import useUserOrdersInfo, { mergedApiOnchainOrders } from "hooks/useUserOrdersInfo";

let justOpenPositions = [];
let closePositionTempt = [];
export const ExchangeV2 = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedSlippageAmount,
    setSavedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    connectWallet,
    savedShouldDisableValidationForTesting,
    setTradeVersionState,
  } = props;

  const [pendingPositions, setPendingPositions] = useState({});
  const [showChart, setShowChart] = useState(false);
  const isPadScreen = useMediaQuery({ query: "(max-width: 1350px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 700px)" });
  const { lightThemeClassName, ...theme } = useThemeContext();
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const [defaultChartToken, setDefaultChartToken] = useLocalStorageSerializeKey(
    [chainId, "default-chart-token"],
    "BTC/USD"
  );
  const marketAsssets = useMarketAssets();

  const marketAsssetList = Object.keys(marketAsssets).map((key) => marketAsssets[key]);

  const marketPrices = useAssetsPrices();
  const marketTokensInfo = getMarketTokensInfo(marketAsssetList, marketPrices);
  // const orderOnchain = useUserOrdersInfo(account);
  const readerMspAddress = getContract(chainId, "ReaderMsp");
  const { positions: myOrdersApi, isLoading: isLoadingApi } = useUserOrder(account);
  const { positions: myOrdersOnchain, isLoading: isLoadingOnchain } = useUserOrdersInfo(account);
  const isLoading = isLoadingApi && isLoadingOnchain;
  // const myOrders = mergedApiOnchainOrders(myOrdersOnchain, myOrdersApi);
  const myOrders = mergedApiOnchainOrders(myOrdersOnchain, []);

  const { positions: positionsOnChain, loading } = useUserTradeInfo(account);
  const { positions: positionsApi, positionsDataIsLoadingApi } = useUserPosition(account);
  const positionsDataIsLoading = loading &&  positionsDataIsLoadingApi;
  let positions = getCompletePosition(positionsOnChain, positionsApi);
  // let positions = getCompletePosition(positionsOnChain, []);
  if (justOpenPositions && justOpenPositions.length > 0) {
    const listNew = justOpenPositions.filter((x) => !positions.find((y) => y.posId === x.posId));
    if (listNew.length > 0) {
      positions = [...listNew.reverse(), ...positions];
    }
  }
  if (closePositionTempt && closePositionTempt.length > 0) {
    positions = positions.filter((x) => !closePositionTempt.find((y) => y.posId === x.posId));
  }
  const UserStats = useUserStats(account);

  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "Trade-option-v2", LONG);

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const tokens = getTokens(chainId);

  const { data: userTokenBalances } = useSWR(
    active && [`Exchange:getUserBalances:${active}:${account}`, chainId, readerMspAddress, "getUserBalances", account],
    {
      fetcher: contractFetcher(library, ReaderMsp),
    }
  );

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);
  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  });
  const [isProChart, setIsProChart] = useState(false);

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Close Positions";
  const TRADE_ACTIVITY = "Trade Activity";
  var oldChain = useRef(null);
  const LIST_SECTIONS = [POSITIONS, ORDERS, TRADES, TRADE_ACTIVITY].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "trade-List-section-v2", LIST_SECTIONS[0]);
  const [posOrders, setPosOrders] = useState();
  const history = useHistory();
  const query = useRouteQuery();
  const previousChain = useRef(null);
  useEffect(() => {
    if (listSection !== ORDERS) {
      setPosOrders(undefined);
    }
  }, [listSection]);
  useEffect(() => {
    if (chainId !== FANTOM && chainId !== OP && account) {
      let isSwitch = query.get("switch");
      if (!isSwitch) {
        if (!isSwitch) history.push("/v1");
      } else {
        if (previousChain.current) {
          if (previousChain.current !== chainId) history.push("/v1");
        }
      }
    }
    if (previousChain.current !== chainId) previousChain.current = chainId;
  }, [chainId]);
  const LIST_SECTIONS_LABELS = {
    [ORDERS]: `Open Orders V2 (${myOrders.length || 0})`,
    [POSITIONS]: `Open Positions V2 (${positions.length || 0})`,
    [TRADES]: `Closed Positions V2 (${UserStats?.closedCount || 0})`,
    [TRADE_ACTIVITY]: `Trade Activities V2 (${UserStats?.trades || 0})`,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  // useEffect(() => {
  //   if(chainId && chainId !== FANTOM) {
  //     history.push("/v1")
  //   }
  // }, [chainId])
  const currentMarket = marketTokensInfo?.find((x) => x.symbol === defaultChartToken);
  const getListSection = () => {
    return (
      <div>
        <div className="Exchange-list-tab-container">
          <Tab1
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          <div className="align-right Exchange-should-show-position-lines">
            {/* {renderCancelOrderButton()} */}
            {!isProChart ? (
              <Checkbox
                isChecked={savedShouldShowPositionLines}
                setIsChecked={setSavedShouldShowPositionLines}
                className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
              >
                <div className="group-actions">
                  <span>Chart positions</span>
                </div>
              </Checkbox>
            ) : null}
          </div>
        </div>

        <div className="group-switch-mobile" onClick={() => setIsProChart(!isProChart)}>
          <div
            style={{
              color: !isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Basic
          </div>
          <span>/</span>
          <div
            style={{
              color: isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Pro
          </div>
        </div>

        {listSection === POSITIONS && (
          <PositionsList
            setListSection={setListSection}
            active={active}
            account={account}
            library={library}
            setPendingTxns={setPendingTxns}
            chainId={chainId}
            marketAsssets={marketAsssets}
            marketTokensInfo={marketTokensInfo}
            userTokenBalances={userTokenBalances}
            myOrders={myOrders}
            setPosOrders={setPosOrders}
            positions={positions}
            loading={positionsDataIsLoading}
            justOpenPositions={justOpenPositions}
            closePositionTempt={closePositionTempt}
            positionsDataIsLoading={positionsDataIsLoading}
            theme={theme}
            savedSlippageAmount={savedSlippageAmount}
          />
        )}
        {listSection === ORDERS && (
          <OrderListNew
            active={active}
            account={account}
            library={library}
            chainId={chainId}
            marketAsssets={marketAsssets}
            marketTokensInfo={marketTokensInfo}
            myOrders={posOrders || myOrders}
            loading={isLoading}
          />
        )}
        {listSection === TRADES && (
          <ClosedPositionsList
            marketAsssets={marketAsssets}
            account={account}
            chainId={chainId}
            marketTokensInfo={marketTokensInfo}
          />
        )}
        {listSection === TRADE_ACTIVITY && (
          <TradeActivityList marketAsssets={marketAsssets} account={account} chainId={chainId} />
        )}
      </div>
    );
  };

  const renderChart = () => {
    return (
      <ExchangeTVChart
        isProChart={isProChart}
        chartToken={chartToken}
        setChartToken={setChartToken}
        swapOption={swapOption}
        chainId={chainId}
        positions={positionsApi}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        orders={myOrders}
        marketPrices={marketPrices}
        marketAsssetList={marketTokensInfo}
        defaultChartToken={defaultChartToken}
        setDefaultChartToken={setDefaultChartToken}
        marketAsssets={marketAsssets}
        theme={theme}
        setTradeVersionState={setTradeVersionState}
      />
    );
  };

  return (
    <SEO
      title={getTradePageTitle(
        currentMarket?.askPrice ? formatAmount(currentMarket?.askPrice, 30, currentMarket?.decimals || 2, true) : null,
        currentMarket?.symbol
      )}
    >
      <div className={`exchange-v2 ${lightThemeClassName}`}>
        <div className="Exchange page-layout Exchange-updated-ui">
          <div className={`Exchange-content ${showChart ? "chart-pad-show-content" : ""}`}>
            <div className="Exchange-left">
              {renderChart()}
              {isProChart ? <TradingView symbol={chartToken?.symbol} /> : null}
              <div className="Exchange-lists large">{getListSection()}</div>
            </div>
            <div className="Exchange-right">
              <SwapBox
                pendingPositions={pendingPositions}
                setPendingPositions={setPendingPositions}
                setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
                setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
                isPluginApproving={isPluginApproving}
                isPositionRouterApproving={isPositionRouterApproving}
                isWaitingForPluginApproval={isWaitingForPluginApproval}
                isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
                chainId={chainId}
                active={active}
                connectWallet={connectWallet}
                library={library}
                account={account}
                positionsMap={[]}
                swapOption={swapOption}
                setSwapOption={setSwapOption}
                pendingTxns={pendingTxns}
                setPendingTxns={setPendingTxns}
                isConfirming={isConfirming}
                setIsConfirming={setIsConfirming}
                isPendingConfirmation={isPendingConfirmation}
                setIsPendingConfirmation={setIsPendingConfirmation}
                savedIsPnlInLeverage={savedIsPnlInLeverage}
                setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                savedSlippageAmount={savedSlippageAmount}
                setSavedSlippageAmount={setSavedSlippageAmount}
                savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                chartToken={chartToken}
                setChartToken={setChartToken}
                userTokenBalances={userTokenBalances}
                marketTokenInfo={marketTokensInfo}
                defaultChartToken={defaultChartToken}
                justOpenPositions={justOpenPositions}
              />
            </div>
            <div className="Exchange-lists small">{getListSection()}</div>
          </div>
          {isPadScreen && (
            <div className={`pad-chart-container ${showChart ? "chart-pad-open" : ""}`}>
              <div className="show-ui-content">
                <div className="index-chart">
                  <img src={theme?.isLightTheme ? ChartPadLight : ChartPad} alt="" />{" "}
                  {isMobileScreen ? "Chart" : "Index chart"}
                </div>
                <div className="chart-tick-container">
                  {!isProChart && showChart ? (
                    <Checkbox
                      isChecked={savedShouldShowPositionLines}
                      setIsChecked={setSavedShouldShowPositionLines}
                      className={cx("muted chart-positions", {
                        active: savedShouldShowPositionLines,
                      })}
                    >
                      <div className="group-actions">
                        <span>Chart positions</span>
                      </div>
                    </Checkbox>
                  ) : null}
                  <div className="view-chart" onClick={() => setShowChart(!showChart)}>
                    {showChart ? "Hide" : "Show"} chart
                    <img style={showChart ? { transform: "rotate(180deg)" } : {}} src={ArrowUp} alt="" />
                  </div>
                </div>
              </div>
              <div style={!showChart ? { display: "none" } : {}}>
                <ExchangeTVChartPad
                  isProChart={isProChart}
                  chartToken={chartToken}
                  setChartToken={setChartToken}
                  swapOption={swapOption}
                  chainId={chainId}
                  positions={positionsApi}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  orders={myOrders}
                  marketPrices={marketPrices}
                  marketAsssetList={marketTokensInfo}
                  defaultChartToken={defaultChartToken}
                  setDefaultChartToken={setDefaultChartToken}
                  marketAsssets={marketAsssets}
                  theme={theme}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </SEO>
  );
});

const Tab1 = styled(Tab)`
  /* height: 36px; */
  text-align: center;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
  background: var(--natural-1, #1a1919);
  grid-template-columns: 120px 120px;
  user-select: none;
  overflow: hidden;
  box-sizing: content-box;

  * {
    user-select: none;
  }

  > div {
    /* height: 38px; */
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box !important;
    border-radius: 10px;

    &:hover {
      background-color: unset !important;
    }
  }
`;
