import { t } from "@lingui/macro";
import "./Footer.css";
import twitterIcon from "img/ic_twitter.svg";
import twitterIconLight from "img/ic_twitterLight.svg";
import discordIcon from "img/ic_discord.svg";
import discordIconLight from "img/ic_discordLight.svg";
import emailIcon from "img/ic_email.svg";
import telegramIcon from "img/ic_telegram.svg";
import telegramIconLight from "img/ic_telegramLight.svg";
import githubIcon from "img/ic_github.svg";
import mediumIcon from "img/ic_medium.svg";
import mediumIconLight from "img/ic_mediumLight.svg";

type Link = {
  text: string;
  link: string;
  external?: boolean;
  isAppLink?: boolean;
};

type SocialLink = {
  link: string;
  name: string;
  icon: string;
  iconLight: string;
};

export const FOOTER_LINKS: { home: Link[]; app: Link[] } = {
  home: [
    // { text: `Terms and Conditions`, link: "/terms-and-conditions" },
    // { text: `Referral Terms`, link: "/referral-terms" },
    // { text: `Media Kit`, link: "#", external: true },
    // { text: "Jobs", link: "/jobs", isAppLink: true },
  ],
  app: [
    // { text: `Media Kit`, link: "#", external: true },
    // { text: "Jobs", link: "/jobs" },
  ],
};

export const SOCIAL_LINKS: SocialLink[] = [
  { link: "https://x.com/NaviExSonic", name: "Twitter", icon: twitterIcon, iconLight: twitterIconLight },
  { link: "https://medium.com/@mummyftm", name: "Medium", icon: mediumIcon, iconLight: mediumIconLight },
  // { link: "https://github.com/gmx-io", name: "Github", icon: githubIcon, iconLight: githubIconLight },
  { link: "https://t.me/mummyftm", name: "Telegram", icon: telegramIcon, iconLight: telegramIconLight },
  { link: "https://discord.gg/xqSJaw8sUB", name: "Discord", icon: discordIcon, iconLight: discordIconLight },
  // { link: "mailto:contact@mummy.finance", name: "contact", icon: emailIcon, iconLight: emailIconLight },
];
