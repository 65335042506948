import { useCallback, useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import { getServerBaseUrl } from "config/backend";
import axios from "axios";
import { ASSET_LIST_V2_DEFAULT, TOKEN_SHOWS_DECIMALS, TOKEN_WHITE_LIST } from "config/tokens";
import { getTokenSymbolFromString } from "domain/tokens";

const useMarketAssets = () => {
  const [assets, setAssets] = useState({});
  const { chainId } = useChainId();
  const fetchIds = useCallback(async () => {
    try {
      const res = await axios.get(`${getServerBaseUrl(chainId, true)}/assets`, {});
      if (res && res.data?.data) {
        let data = res.data.data || [];
        let list = {};
        data = Object.values(data)?.filter((item) =>
          TOKEN_WHITE_LIST[chainId].includes(getTokenSymbolFromString(item?.symbol))
        );
        for (let i = 0; i < data.length; i++) {
          list[data[i].id] = {
            tokenSymbol: getTokenSymbolFromString(data[i]?.symbol),
            ...data[i],
            decimals: TOKEN_SHOWS_DECIMALS[data[i]?.symbol] || 2,
          };
        }

        setAssets(list);
      } else getLocalAssets();
    } catch (error) {
      getLocalAssets();
    }
  }, [chainId]);
  const getLocalAssets = () => {
    let data = ASSET_LIST_V2_DEFAULT[chainId] || [];
    let list = {};
    data = Object.values(data)?.filter((item) =>
      TOKEN_WHITE_LIST[chainId].includes(getTokenSymbolFromString(item?.symbol))
    );
    for (let i = 0; i < data.length; i++) {
      list[data[i].id] = {
        tokenSymbol: getTokenSymbolFromString(data[i]?.symbol),
        ...data[i],
        decimals: TOKEN_SHOWS_DECIMALS[data[i]?.symbol] || 2,
      };
    }

    setAssets(list);
  };
  useEffect(() => {
    fetchIds();
    const interval = setInterval(async () => {
      fetchIds();
    }, 30000);
    return () => clearInterval(interval);
  }, [chainId]);

  return assets;
};

export default useMarketAssets;
