import { useCallback, useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import axios from "axios";
import { ASSET_LIST_V2_DEFAULT } from "config/tokens";
import { BigNumber } from "ethers";
import { parseValue } from "lib/numbers";
import { USD_DECIMALS } from "lib/legacy";
const useAssetsPrices = () => {
  const [assets, setAssets] = useState({});
  const { chainId } = useChainId();
  const fetchIds = useCallback(async () => {
    try {
      const res = await axios.get(`${getServerBaseUrl(chainId, true)}/assets/prices`, {});
      if (res && res.data?.data) {
        const data = res.data.data || [];
        setAssets(data);
      } else {
        getPythPrice();
      }
      // getPythPrice();
    } catch (error) {
      getPythPrice();
    }
  }, [chainId]);
  const getPythPrice = async () => {
    const pythRes = await axios.get(
      `https://hermes.pyth.network/api/latest_price_feeds?ids[]=${ASSET_LIST_V2_DEFAULT[chainId]
        .map((t) => t.pythId)
        .join("&ids[]=")}&verbose=false&binary=false`,
      {}
    );
    if (pythRes && pythRes.data && pythRes?.data) {
      let data = {};
      for (let i = 0; i < pythRes?.data.length; i++) {
        const pythData = pythRes?.data[i];
        const token = ASSET_LIST_V2_DEFAULT[chainId].find((x) => x.pythId === `0x${pythData.id}`);
        if (token) {
          data[token.id] = {
            askPrice: BigNumber.from(pythData.price?.price || 0).mul(
              parseValue(1, USD_DECIMALS - Math.abs(pythData.price?.expo || 8))
            ),
            bidPrice: BigNumber.from(pythData.price?.price || 0).mul(
              parseValue(1, USD_DECIMALS - Math.abs(pythData.price?.expo || 8))
            ),
            tokenName: token.tokenName,
          };
        }
      }
      setAssets(data);
    }
  };
  useEffect(() => {
    fetchIds();
    const interval = setInterval(async () => {
      fetchIds();
    }, 7000);
    return () => clearInterval(interval);
  }, [fetchIds]);

  return assets;
};

export default useAssetsPrices;
