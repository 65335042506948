import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import "antd/dist/reset.css";
import { BigNumber, ethers } from "ethers";
import useScrollToTop from "lib/useScrollToTop";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSWR, { SWRConfig } from "swr";
import Token from "abis/Token.json";
import ReaderMsp from "abis/ReaderMsp.json";

import { Route, HashRouter as Router, Switch, useHistory, useLocation } from "react-router-dom";

import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_SLIPPAGE_AMOUNT,
  getAppBaseUrl,
  isDevelopment,
  isHomeSite,
  isMobileDevice,
  REFERRAL_CODE_QUERY_PARAM,
} from "lib/legacy";
import styled from "styled-components";
import Actions from "pages/Actions/Actions";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import Buy from "pages/Buy/Buy";
import BuyGlp from "pages/BuyGlp/BuyGlp";
import ClaimEsGmx from "pages/ClaimEsGmx/ClaimEsGmx";
import CompleteAccountTransfer from "pages/CompleteAccountTransfer/CompleteAccountTransfer";
import Dashboard from "pages/Dashboard/Dashboard";
import Ecosystem from "pages/Ecosystem/Ecosystem";
import { MdClose } from "react-icons/md";
import BuyGMX from "pages/BuyGMX/BuyGMX";
import { Exchange } from "pages/Exchange/Exchange";
import { ExchangeV2 } from "pages/ExchangeV2/Exchange";
import Home from "pages/Home/Home";
import NftWallet from "pages/NftWallet/NftWallet";
import OrdersOverview from "pages/OrdersOverview/OrdersOverview";
import PositionsOverview from "pages/PositionsOverview/PositionsOverview";
import Referrals from "pages/Referrals/Referrals";
import Stake from "pages/Stake/Stake";
import VaultFantom from "pages/Vault";
import VaultARB from "pages/Vault/indexARB";
import VaultOP from "pages/Vault/indexOP";

import Checkbox from "components/Checkbox/Checkbox";
import Modal from "components/Modal/Modal";
import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "styles/Font.css";
import "styles/Input.css";
import "styles/Shared.css";
import "./App.scss";
import "./AppTheme.scss";

import SEO from "components/Common/SEO";
import EventToastContainer from "components/EventToast/EventToastContainer";
import useEventToast from "components/EventToast/useEventToast";
import { decodeReferralCode, encodeReferralCode } from "domain/referrals";
import coinbaseImg from "img/coinbaseWallet.png";
import icLink from "img/icons/redirect-green.svg";
import icLinkLight from "img/icons/redirect-greenLight.svg";
import metamaskImg from "img/metamask.svg";
import walletConnectImg from "img/walletconnect-circle-blue.svg";
import useRouteQuery from "lib/useRouteQuery";
import rpcLowIcon from "img/rpc-status-low.svg";
import rpcMediumIcon from "img/rpc-status-medium.svg";
import rpcHighIcon from "img/rpc-status-high.svg";
import rpcLowIconLight from "img/rpc-status-low-light.svg";
import rpcMediumIconLight from "img/rpc-status-medium-light.svg";
import rpcHighIconLight from "img/rpc-status-high-light.svg";
import radioCheckedIcon from "img/radio-checked.svg";
import radioUncheckedIcon from "img/radio-unchecked.svg";
import radioCheckedIconLight from "img/radio-checked-light.svg";
import radioUncheckedIconLight from "img/radio-unchecked-light.svg";
import PositionRouter from "abis/PositionRouter.json";
import VaultV2 from "abis/VaultV2.json";
import VaultV2b from "abis/VaultV2b.json";
import { RedirectPopupModal } from "components/ModalViews/RedirectModal";
import { getContract } from "config/contracts";
import { REDIRECT_POPUP_TIMESTAMP_KEY } from "config/ui";
import Jobs from "pages/Jobs/Jobs";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import { useLocalStorage } from "react-use";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import CircleLoader from "components/CircleLoader/CircleLoader";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { Header } from "components/Header/Header";
import WrongChainModal from "components/Modal/WrongNetworkModal";
import RouterTab from "components/RouterTab";
import {
  ARBITRUM,
  AVALANCHE,
  BASE,
  FANTOM,
  getExplorerUrl,
  MICROSWAP_SUPPORTED_CHAIN_IDS,
  MUMBAI,
  OP,
  RPC_NAMES,
  RPC_PROVIDERS,
  SUPPORT_MERGED_ANALYTICS_CHAIN_IDS,
} from "config/chains";
import {
  APP_RPC_INDEX,
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
  CUSTOM_RPC,
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  LANGUAGE_LOCALSTORAGE_KEY,
  REFERRAL_CODE_KEY,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
  THEME_KEY,
} from "config/localStorage";
import StatsProvider from "contexts/StatsProvider";
import StatsProviderV2 from "contexts/StatsProviderV2";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import { defaultLocale, dynamicActivate } from "lib/i18n";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import {
  activateInjectedProvider,
  clearWalletConnectData,
  clearWalletLinkData,
  getInjectedHandler,
  getWalletConnectV2Handler,
  hasCoinBaseWalletExtension,
  hasMetaMaskWalletExtension,
  useEagerConnect,
  useInactiveListener,
} from "lib/wallets";
import Analytics from "pages/Analytics/Analytics";
import AnalyticsV2 from "pages/AnalyticsV2/index.tsx";
import Bridge from "pages/Bridge/Bridge";
import HomeV3 from "pages/Home/HomeV3";
import Lock from "pages/LiquidityLock/Lock";
import MigrateOP from "pages/MigrateOP";
import News from "pages/News";
import NFT from "pages/NFT/NFT";
import StakeNFT from "pages/NFT/StakeNFT";
import YourNFT from "pages/NFT/YourNFT";
import VoteGitcoin from "pages/VoteGitcoin";
import MintRedeem from "pages/Buy/MintRedeem";
import AccountOverview from "pages/AccountOverview";
import { SwapV2 } from "pages/SwapV2/SwapV2";
import { SpotThroughArregator } from "pages/SpotThroughArregator";
import ThemeProvider, { useThemeContext } from "contexts/ThemeProvider";
import { contractFetcher } from "lib/contracts";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { useWebsocketProvider, WebsocketContextProvider } from "./WebsocketContextProvider";
import AprProvider from "contexts/AprProvider";
import WormHole from "pages/WormHole/WormHole";
import { DEFAULT_MICROSWAP_SELECTED_LIQ_SOURCE, MICROSWAP_DEXES } from "config/dex";
import AnalyticsV3 from "pages/AnalyticsV3";
import AnalyticsSplits from "pages/AnalyticsV3/AnalyticsSplits";
import LeaderBoardSplits from "pages/Analytics/LeaderBoardSplits";
import { checkMultipleRpcLatencies, checkRpcLatency } from "lib/rpc";
import { commify } from "@ethersproject/units";

// if ("ethereum" in window) {
//   window.ethereum.autoRefreshOnNetworkChange = false;
// }
if (window.ethereum !== undefined) {
  (window.ethereum || {}).autoRefreshOnNetworkChange = false;
}

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  return library;
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

// const arbWsProvider = new ethers.providers.WebSocketProvider(getAlchemyWsUrl());

// const avaxWsProvider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");
// avaxWsProvider.pollingInterval = 2000;
// const ftmWsProvider = new ethers.providers.JsonRpcProvider("https://rpc.ftm.tools");
// const mumbaisProvider = new ethers.providers.JsonRpcProvider("https://rpc-mumbai.maticvigil.com");

// const avaxsProvider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");

// const opProvider = new ethers.providers.JsonRpcProvider("https://optimism.publicnode.com");

// const baseProvider = new ethers.providers.JsonRpcProvider("https://base.publicnode.com");

// function getWsProvider(active, chainId) {
//   if (!active) {
//     return;
//   }
//   // if (chainId === ARBITRUM) {
//   //   return arbWsProvider;
//   // }

//   if (chainId === MUMBAI) {
//     return mumbaisProvider;
//   }
//   if (chainId === AVALANCHE) {
//     return avaxsProvider;
//   }
//   if (chainId === OP) {
//     return opProvider;
//   }
//   if (chainId === BASE) {
//     return baseProvider;
//   }

//   if (chainId === FANTOM) {
//     return ftmWsProvider;
//   }
// }
const DEFAULT_LIST_SLIPPAGE = [0.1, 0.5, 1, 2];
function FullApp() {
  const isHome = isHomeSite();
  const exchangeRef = useRef();
  const { connector, library, deactivate, activate, active, account } = useWeb3React();
  const { chainId } = useChainId();
  const location = useLocation();
  const history = useHistory();
  const rpcListRef = useRef(null);
  const [rpcs, setRpcs] = useState([]);
  const [customRPCLatency, setCustomRPCLatency] = useState(null);
  const [inputCustomRPC, setInputCustomRPC] = useState("");
  const [showRPC, setShowRPC] = useState(false);
  const readerMspAddress = getContract(chainId, "ReaderMsp");
  const isLanding = history.location.pathname === "/" ? true : false;
  const [tradeVersion, setTradeVersion] = useState(() => {
    if (window.localStorage.getItem('["trade-version"]')) {
      return JSON.parse(window.localStorage.getItem('["trade-version"]'));
    }
    return "V2";
  });
  const isApp = history.location.pathname !== "/";

  // useEventToast();
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    const fetchRpc = async () => {
      const rpcsData = await checkMultipleRpcLatencies(RPC_PROVIDERS[chainId], RPC_NAMES[chainId]);
      setRpcs(rpcsData);
    };
    const fetCustomRPCLatency = async () => {
      if (customRPC) {
        const latency = await checkRpcLatency(customRPC);
        if (latency && latency !== -1) setCustomRPCLatency(latency);
        else {
          setRPCIndex(0);
          setCustomRPC("");
        }
      }
    };
    fetchRpc();
    fetCustomRPCLatency();
    const interval = setInterval(async () => {
      fetchRpc();
      fetCustomRPCLatency();
    }, 30000);
    return () => clearInterval(interval);
  }, [chainId]);
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, chainId]);
  const triedEager = useEagerConnect(setActivatingConnector);
  useInactiveListener(!triedEager || !!activatingConnector);

  // useHandleUnsupportedNetwork();
  // console.log("?????", rpcs);

  const query = useRouteQuery();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (rpcListRef.current && !rpcListRef.current.contains(event.target)) {
      setTimeout(() => setShowRPC(false), 150);
    }
  };
  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAM);
    if (!referralCode || referralCode.length === 0) {
      const params = new URLSearchParams(window.location.search);
      referralCode = params.get(REFERRAL_CODE_QUERY_PARAM);
    }

    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode);
      if (encodeReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode);
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(REFERRAL_CODE_QUERY_PARAM)) {
          queryParams.delete(REFERRAL_CODE_QUERY_PARAM);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [query, history, location]);

  const disconnectAccount = useCallback(() => {
    // only works with WalletConnect
    clearWalletConnectData();
    // force clear localStorage connection for MM/CB Wallet (Brave legacy)
    clearWalletLinkData();
    deactivate();
  }, [deactivate]);

  const disconnectAccountAndCloseSettings = () => {
    disconnectAccount();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    setIsSettingsVisible(false);
  };

  const connectInjectedWallet = getInjectedHandler(activate, deactivate);
  const activateWalletConnectV2 = useCallback(() => {
    getWalletConnectV2Handler(activate, deactivate, setActivatingConnector, setWalletModalVisible)();
  }, [activate, deactivate]);

  const attemptActivateWallet = useCallback(
    (providerName) => {
      localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, true);
      localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
      activateInjectedProvider(providerName);
      connectInjectedWallet();
    },
    [connectInjectedWallet]
  );

  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const activateMetaMask = useCallback(() => {
    if (!hasMetaMaskWalletExtension()) {
      helperToast.error(
        <div>
          <span>MetaMask not detected.</span>
          <br />
          <br />
          {userOnMobileDevice ? (
            <span>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink>, and use MUMMY with its built-in
              browser
            </span>
          ) : (
            <span>
              <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink> to start using MUMMY
            </span>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("MetaMask");
  }, [attemptActivateWallet, userOnMobileDevice]);

  const activateCoinBase = useCallback(() => {
    if (!hasCoinBaseWalletExtension()) {
      helperToast.error(
        <div>
          <span>Coinbase Wallet not detected.</span>
          <br />
          <br />
          {userOnMobileDevice ? (
            <span>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink>, and use MUMMY
              with its built-in browser
            </span>
          ) : (
            <span>
              <ExternalLink href="https://www.coinbase.com/wallet">Install Coinbase Wallet</ExternalLink> to start using
              MUMMY
            </span>
          )}
        </div>
      );
      return false;
    }
    attemptActivateWallet("CoinBase");
  }, [attemptActivateWallet, userOnMobileDevice]);

  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [shouldHideRedirectModal, setShouldHideRedirectModal] = useState(false);
  const [redirectPopupTimestamp, setRedirectPopupTimestamp] = useLocalStorage(REDIRECT_POPUP_TIMESTAMP_KEY);
  const [rpcIndexTemp, setRpcIndexTemp] = useState(0);
  const [selectedToPage, setSelectedToPage] = useState("");
  const connectWallet = () => setWalletModalVisible(true);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [savedSelectedDexes, setSavedSelectedDexes] = useLocalStorageSerializeKey(
    [chainId, "micro-swap-selected-dexes"],
    DEFAULT_MICROSWAP_SELECTED_LIQ_SOURCE[MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) ? chainId : FANTOM]
  );
  const [temptLiqSource, setTemptLiqSource] = useState(savedSelectedDexes);
  const [slippageAmount, setSlippageAmount] = useState(0);
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [shouldDisableValidationForTesting, setShouldDisableValidationForTesting] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false);

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );
  const [customRPC, setCustomRPC] = useLocalStorageSerializeKey([chainId, CUSTOM_RPC], undefined);
  const [rpcIndex, setRPCIndex] = useLocalStorageSerializeKey([chainId, APP_RPC_INDEX], 0);

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false
  );
  const [savedShouldDisableValidationForTesting, setSavedShouldDisableValidationForTesting] =
    useLocalStorageSerializeKey([chainId, DISABLE_ORDER_VALIDATION_KEY], false);

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );
  useEffect(() => {
    // window.location.reload();
    setInputCustomRPC(customRPC);
  }, []);
  useEffect(() => {
    if (showRPC) {
      setRpcIndexTemp(rpcIndex);
    }
  }, [showRPC]);
  const handleChooseLiqSource = (dexId) => {
    let result = `${temptLiqSource}`;
    if (temptLiqSource.includes(`${dexId},`)) {
      result = temptLiqSource.replace(`${dexId},`, "");
    } else result += `${dexId},`;
    setTemptLiqSource(result);
  };
  const openSettings = () => {
    // const slippage = savedSlippageAmount;
    // setSlippageAmount(slippage / 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    setShowPnlAfterFees(savedShowPnlAfterFees);
    setShouldDisableValidationForTesting(savedShouldDisableValidationForTesting);
    setIsSettingsVisible(true);
  };
  useEffect(() => {
    if (!isSettingsVisible) {
      setTemptLiqSource(savedSelectedDexes);
    } else {
      const slippage = savedSlippageAmount / 100;
      setSlippageAmount(slippage);
    }
  }, [isSettingsVisible]);
  useEffect(() => {
    if (showRPC) {
      setInputCustomRPC(customRPC);
      if (rpcIndex === -1 && !customRPC) {
        setRPCIndex(0);
      }
    }
  }, [showRPC]);
  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    setSavedSelectedDexes(temptLiqSource);
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedShouldDisableValidationForTesting(shouldDisableValidationForTesting);
    setSavedSlippageAmount(basisPoints);
    setIsSettingsVisible(false);
  };

  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const baseUrl = getAppBaseUrl();
  let appRedirectUrl = baseUrl + selectedToPage;
  if (localStorageCode && localStorageCode.length > 0 && localStorageCode !== ethers.constants.HashZero) {
    const decodedRefCode = decodeReferralCode(localStorageCode);
    if (decodedRefCode) {
      appRedirectUrl = `${appRedirectUrl}?ref=${decodedRefCode}`;
    }
  }

  const [pendingTxns, setPendingTxns] = useState([]);

  const showRedirectModal = (to) => {
    // setRedirectModalVisible(true);
    setSelectedToPage(to);
  };

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        const receipt = await library.getTransactionReceipt(pendingTxn.hash);
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                <span>
                  Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                </span>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <ExternalLink href={txUrl}>
                  <span>View</span>
                </ExternalLink>
                <br />
              </div>
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [library, pendingTxns, chainId]);

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const { wsProvider } = useWebsocketProvider();

  useEffect(() => {
    const wsVaultAbi = chainId === ARBITRUM ? VaultV2.abi : VaultV2b.abi;
    // const wsProvider = getWsProvider(active, chainId);

    if (!wsProvider) {
      return;
    }

    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);

    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);
    };
  }, [active, chainId, vaultAddress, positionRouterAddress]);

  const [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    !walletModalVisible && setSelectedWallet(null);
  }, [selectedWallet, walletModalVisible]);

  const wallets = useMemo(() => {
    return [
      {
        className: "MetaMask-btn",
        onClick: activateMetaMask,
        logo: metamaskImg,
        title: "MetaMask",
      },
      {
        className: "CoinbaseWallet-btn",
        onClick: activateCoinBase,
        logo: coinbaseImg,
        title: "Coinbase Wallet",
      },
      {
        className: "WalletConnect-btn",
        onClick: activateWalletConnectV2,
        logo: walletConnectImg,
        title: "WalletConnect",
      },
    ];
  }, [activateCoinBase, activateMetaMask, activateWalletConnectV2]);
  let notiTitle = "";
  let notiDes = "";
  switch (chainId) {
    case FANTOM: {
      notiTitle = "Withdraw your esMMY in v1!";
      notiDes = (
        <div>
          After August 1st 12:00 PM (UTC), ALL esMMY vesting on v1 will become unwithdrawable. Please{" "}
          <a target="_self" href="https://legacy.mummy.finance/#/earn-v2/vest">
            withdraw
          </a>{" "}
          all esMMY and transfer them to v2 now.
        </div>
      );
      break;
    }
    case OP: {
      notiTitle = "Migrate your MMY now!";
      notiDes = (
        <div>
          The{" "}
          <a target="_self" href="https://optimistic.etherscan.io/address/0x47536f17f4ff30e64a96a7555826b8f9e66ec468">
            multiMMY{" "}
          </a>{" "}
          will be abandoned, and migration support will not be available from August 9th, 00:00 AM UTC. Please complete
          your{" "}
          <a target="_self" href=" https://app.mummy.finance/#/migrate">
            migration
          </a>{" "}
          before the deadline.
        </div>
      );
      break;
    }

    default:
      break;
  }

  const [hiddenNotiState, setHiddenNotiState] = useState(false);

  const handleCloseNoti = () => {
    setHiddenNotiState(true);
  };

  const renderVault = () => {
    // if (chainId === OP) return <VaultOP setPendingTxns={setPendingTxns} connectWallet={connectWallet} />;
    if (chainId === ARBITRUM) return <VaultARB setPendingTxns={setPendingTxns} connectWallet={connectWallet} />;
    return <VaultFantom setPendingTxns={setPendingTxns} connectWallet={connectWallet} />;
  };
  const { data: usdcBalance } = useSWR(
    [`User:usdcBalance:${chainId}`, chainId, getContract(chainId, "USDC"), "balanceOf", account || ADDRESS_ZERO],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  // const { data: nusdBalance } =
  //   useSWR <
  //   BigNumber >
  //   ([`StakeV2:nusdBalance:${chainId}`, chainId, getContract(chainId, "NUSD"), "balanceOf", account || ADDRESS_ZERO],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //   });

  const { data: userTokenBalances } = useSWR(
    active && [`Exchange:getUserBalances:${active}:${account}`, chainId, readerMspAddress, "getUserBalances", account],
    {
      fetcher: contractFetcher(library, ReaderMsp),
    }
  );

  return (
    <>
      <div className={`App ${lightThemeClassName}`}>
        {/* <Snowflakes class="snowflakes" aria-hidden="true">
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
          <div class="snowflake">❅</div>
          <div class="snowflake">❆</div>
        </Snowflakes> */}
        <WrongChainModal />
        <div className="App-content">
          {isLanding && (
            <video className="videoTag" autoPlay loop muted playsInline>
              <source src={"https://cdn.mummy.finance/bg_videoMMY.mp4"} type="video/mp4" />
            </video>
          )}
          {Date.now() < 1692180000000 && notiTitle && notiDes && !hiddenNotiState && location?.pathname !== "/" ? (
            <Notice>
              <span>
                <strong>EsMMY migration period:</strong> August 2nd - August 16th (14 days). Complete{" "}
                <ExternalLink href="https://app.mummy.finance/#/earn-v2/vest">
                  <span>migration</span>
                </ExternalLink>{" "}
                before the deadline. Affects rewarded esMMY on Fantom V1 and bonus esMMY from minting NFTs on Optimism.
              </span>
              <MdClose fontSize={20} className="Modal-close-icon" onClick={handleCloseNoti} />
            </Notice>
          ) : null}
          <Header
            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            openSettings={openSettings}
            setWalletModalVisible={setWalletModalVisible}
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
            tradeVersion={tradeVersion}
            account={account}
            library={library}
            usdcBalance={usdcBalance}
            nusdBalance={userTokenBalances?.nusdBalance}
            savedSlippageAmount={savedSlippageAmount}
            savedSelectedDexes={savedSelectedDexes}
          />
          {isHome && (
            <Switch>
              <Route exact path="/">
                <AprProvider active={active}>
                  <Home showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
                </AprProvider>
              </Route>
              {/* <Route exact path="/referral-terms">
                <ReferralTerms />
              </Route>
              <Route exact path="/terms-and-conditions">
                <TermsAndConditions />
              </Route> */}
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          )}

          {!isHome && (
            <Switch>
              {/* <RouterTab /> */}
              <Route exact path="/">
                <AprProvider active={active}>
                  <StatsProvider>
                    {/* <Home showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} /> */}
                    <HomeV3 showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
                  </StatsProvider>
                </AprProvider>
              </Route>
              <Route exact path="/v1">
                <Exchange
                  ref={exchangeRef}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                  savedSlippageAmount={savedSlippageAmount}
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                />
              </Route>
              <Route exact path="/spot-native">
                <SwapV2
                  ref={exchangeRef}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                  savedSlippageAmount={savedSlippageAmount}
                  setIsSettingsSlippageVisible={setIsSettingsVisible}
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                />
              </Route>
              <Route exact path="/swap">
                <SpotThroughArregator
                  ref={exchangeRef}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                  savedSlippageAmount={savedSlippageAmount}
                  setIsSettingsSlippageVisible={setIsSettingsVisible}
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  savedSelectedDexes={savedSelectedDexes}
                />
              </Route>
              <Route exact path="/trade">
                <ExchangeV2
                  ref={exchangeRef}
                  savedShowPnlAfterFees={savedShowPnlAfterFees}
                  savedIsPnlInLeverage={savedIsPnlInLeverage}
                  setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                  savedSlippageAmount={savedSlippageAmount}
                  setSavedSlippageAmount={setSavedSlippageAmount}
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                  setTradeVersionState={setTradeVersion}
                />
              </Route>
              <Route exact path="/portfolio">
                <AccountOverview
                  setPendingTxns={setPendingTxns}
                  pendingTxns={pendingTxns}
                  connectWallet={connectWallet}
                  savedSlippageAmount={savedSlippageAmount}
                  savedSelectedDexes={savedSelectedDexes}
                />
              </Route>
              <Route exact path="/dashboard">
                <AprProvider active={active}>
                  <StatsProvider>
                    <Dashboard setTradeVersion={setTradeVersion} />
                  </StatsProvider>
                </AprProvider>
              </Route>

              <Route exact path="/proof-of-locked-liquidity">
                <Lock />
              </Route>

              <Route exact path="/nft">
                <NFT connectWallet={connectWallet} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
              </Route>
              <Route exact path="/stake-nft">
                <StakeNFT connectWallet={connectWallet} setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
              </Route>
              <Route exact path="/your-nft">
                <YourNFT />
              </Route>
              {Date.now() < 1692180000000 && (
                <Route exact path="/migrate">
                  <MigrateOP setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} connectWallet={connectWallet} />
                </Route>
              )}

              <Route exact path={["/bridge/*", "/bridge"]}>
                <Bridge setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} connectWallet={connectWallet} />
              </Route>
              <Route path="/bridge-wormhole">
                <WormHole setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} connectWallet={connectWallet} />
              </Route>
              {/* <Route exact path="/claim-gcv">
                  <VoteGitcoin
                    setPendingTxns={setPendingTxns}
                    pendingTxns={pendingTxns}
                    connectWallet={connectWallet}
                  />
                </Route> */}
              <Route exact path="/earn">
                <Stake setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
              </Route>
              <Route path={`/vault`} exact>
                {renderVault()}
                {/* {chainId === FANTOM && <VaultFantom setPendingTxns={setPendingTxns} connectWallet={connectWallet} />}
                {chainId === OP && <VaultOP setPendingTxns={setPendingTxns} connectWallet={connectWallet} />}
                {chainId === ARBITRUM && <VaultARB setPendingTxns={setPendingTxns} connectWallet={connectWallet} />} */}
              </Route>
              <Route path="/analytic-v1">
                <Analytics setPendingTxns={setPendingTxns} />
              </Route>
              <Route path="/leaderboard">
                <LeaderBoardSplits setPendingTxns={setPendingTxns} />
              </Route>
              <Route path="/analytics">
                {SUPPORT_MERGED_ANALYTICS_CHAIN_IDS.includes(chainId) ? (
                  <AnalyticsV3 setPendingTxns={setPendingTxns} />
                ) : (
                  <AnalyticsSplits setPendingTxns={setPendingTxns} />
                )}
              </Route>
              <Route path="/analytic-v3"></Route>
              <Route exact path="/buy">
                <AprProvider active={active}>
                  <Buy
                    // savedSlippageAmount={savedSlippageAmount}
                    // setPendingTxns={setPendingTxns}
                    // connectWallet={connectWallet}
                    chainId={chainId}
                    active={active}
                    library={library}
                  />
                </AprProvider>
              </Route>
              <Route exact path={["/buy/mint", "/buy/redeem"]}>
                <MintRedeem
                  setPendingTxns={setPendingTxns}
                  connectWallet={connectWallet}
                  savedSlippageAmount={savedSlippageAmount}
                  savedSelectedDexes={savedSelectedDexes}
                />
              </Route>
              <Route path="/analytic-v2">
                <StatsProviderV2>
                  <AnalyticsV2 setPendingTxns={setPendingTxns} />
                </StatsProviderV2>
              </Route>
              <Route exact path="/buy">
                <Buy
                  savedSlippageAmount={savedSlippageAmount}
                  setPendingTxns={setPendingTxns}
                  connectWallet={connectWallet}
                />
              </Route>
              <Route exact path="/buy_mlp">
                <BuyGlp
                  savedSlippageAmount={savedSlippageAmount}
                  setPendingTxns={setPendingTxns}
                  connectWallet={connectWallet}
                  savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
                />
              </Route>
              <Route exact path="/jobs">
                <Jobs />
              </Route>
              <Route exact path="/buy_mmy">
                <AprProvider active={active}>
                  <BuyGMX />
                </AprProvider>
              </Route>
              <Route exact path="/ecosystem">
                <Ecosystem />
              </Route>
              <Route exact path="/referrals">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>
              <Route exact path="/referrals/:account">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>
              <Route exact path="/nft_wallet">
                <NftWallet />
              </Route>
              <Route exact path="/claim_es_gmx">
                <ClaimEsGmx setPendingTxns={setPendingTxns} />
              </Route>
              <Route exact path="/actions">
                <Actions />
              </Route>
              <Route exact path="/actions/:account">
                <Actions />
              </Route>
              <Route exact path="/news">
                <News />
              </Route>
              <Route exact path="/orders_overview">
                <OrdersOverview />
              </Route>
              <Route exact path="/positions_overview">
                <PositionsOverview />
              </Route>
              <Route exact path="/begin_account_transfer">
                <BeginAccountTransfer setPendingTxns={setPendingTxns} />
              </Route>
              <Route exact path="/complete_account_transfer/:sender/:receiver">
                <CompleteAccountTransfer setPendingTxns={setPendingTxns} />
              </Route>

              <Route path="/earn-v2">
                <RouterTab setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
              </Route>

              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          )}
          {isApp && (
            <div className="rpc-select-container">
              {showRPC && (
                <div className="rpc-options-container">
                  <div className="rpc-options" ref={rpcListRef}>
                    <div className="title">RPC Endpoint</div>
                    <div className="rpc-items">
                      {rpcs.map((item, index) => (
                        <div className="rpc-item">
                          <div
                            className="rpc-item-info"
                            onClick={() => {
                              if (index !== rpcIndex) {
                                setCustomRPC("");
                                setInputCustomRPC("");
                                setRPCIndex(index);
                                setShowRPC(false);
                                window.location.reload();
                              } else setRpcIndexTemp(index);
                            }}
                          >
                            <div className="checkbox">
                              <img
                                alt="checkbox"
                                src={
                                  index === rpcIndexTemp
                                    ? isLightTheme
                                      ? radioCheckedIconLight
                                      : radioCheckedIcon
                                    : isLightTheme
                                    ? radioUncheckedIconLight
                                    : radioUncheckedIcon
                                }
                              />
                            </div>
                            <div className={`title ${index !== rpcIndexTemp ? "muted" : ""}`}>{item.name}</div>
                          </div>
                          <div className={`rpc-item-latency ${index !== rpcIndexTemp ? "muted" : ""}`}>
                            {commify(item.latency < 0 ? 10000 : item.latency)}ms{" "}
                            <img
                              alt="rpc status"
                              src={
                                item.latency <= 200 && item.latency !== -1
                                  ? isLightTheme
                                    ? rpcHighIconLight
                                    : rpcHighIcon
                                  : item.latency <= 400 && item.latency !== -1
                                  ? isLightTheme
                                    ? rpcMediumIconLight
                                    : rpcMediumIcon
                                  : isLightTheme
                                  ? rpcLowIconLight
                                  : rpcLowIcon
                              }
                            />
                          </div>
                        </div>
                      ))}
                      <div className="rpc-item">
                        <div
                          className="rpc-item-info"
                          onClick={() => {
                            setRpcIndexTemp(-1);
                            // if (customRPC) {
                            //   setShowRPC(false);
                            //   window.location.reload();
                            // }
                          }}
                        >
                          <div className="checkbox">
                            <img
                              alt="checkbox"
                              src={
                                rpcIndexTemp === -1
                                  ? isLightTheme
                                    ? radioCheckedIconLight
                                    : radioCheckedIcon
                                  : isLightTheme
                                  ? radioUncheckedIconLight
                                  : radioUncheckedIcon
                              }
                            />
                          </div>
                          <div className={`title ${rpcIndexTemp !== -1 ? "muted" : ""}`}>Custom</div>
                        </div>
                      </div>
                      <div className="custom-rpc">
                        <input
                          placeholder="Enter custom RPC url"
                          onChange={(e) => setInputCustomRPC(e.target.value)}
                          value={inputCustomRPC}
                          onFocus={() => setRpcIndexTemp(-1)}
                        />

                        <div
                          className={`btn-save ${inputCustomRPC ? "" : "btn-save-disabled"}`}
                          onClick={(e) => {
                            if (inputCustomRPC) {
                              setCustomRPC(inputCustomRPC);
                              // setRPCIndex(-1);
                              if (rpcIndexTemp === -1) {
                                setShowRPC(false);
                                setRPCIndex(-1);
                                window.location.reload();
                              }
                            }
                          }}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="rpc-info-container">
                <div className="oparation-status">
                  <div className="status" />
                  <div className="title">Operational</div>
                </div>
                <div className="stroke" />
                {rpcs && rpcs.length > 0 && (rpcIndex !== -1 || (rpcIndex === -1 && !customRPC)) && (
                  <div
                    className="rpc-status-container"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowRPC(!showRPC);
                    }}
                  >
                    <div className="status">
                      <img
                        alt="rpc status"
                        src={
                          rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency <= 200 &&
                          rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency !== -1
                            ? isLightTheme
                              ? rpcHighIconLight
                              : rpcHighIcon
                            : rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency <= 400 &&
                              rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency !== -1
                            ? isLightTheme
                              ? rpcMediumIconLight
                              : rpcMediumIcon
                            : isLightTheme
                            ? rpcLowIconLight
                            : rpcLowIcon
                        }
                      />
                    </div>
                    <div className="rpc-info">
                      RPC: {rpcs[rpcIndex === -1 ? 0 : rpcIndex].name}{" "}
                      <span
                        className={`rpc-status ${
                          rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency <= 200 &&
                          rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency !== -1
                            ? "rpc-status-high"
                            : rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency <= 400 &&
                              rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency !== -1
                            ? "rpc-status-medium"
                            : "rpc-status-low"
                        }`}
                      >
                        (
                        {commify(
                          rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency < 0
                            ? 10000
                            : rpcs[rpcIndex === -1 ? 0 : rpcIndex].latency
                        )}
                        ms)
                      </span>
                    </div>
                  </div>
                )}
                {customRPC && rpcIndex === -1 && (
                  <div
                    className="rpc-status-container"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowRPC(!showRPC);
                    }}
                  >
                    <div className="status">
                      {customRPCLatency && (
                        <img
                          alt="rpc status"
                          src={
                            customRPCLatency <= 200 && customRPCLatency !== -1
                              ? isLightTheme
                                ? rpcHighIconLight
                                : rpcHighIcon
                              : customRPCLatency <= 400 && customRPCLatency !== -1
                              ? isLightTheme
                                ? rpcMediumIconLight
                                : rpcMediumIcon
                              : isLightTheme
                              ? rpcLowIconLight
                              : rpcLowIcon
                          }
                        />
                      )}
                    </div>
                    <div className="rpc-info">
                      RPC: Custom{" "}
                      <span
                        className={`rpc-status ${
                          customRPCLatency <= 200 && customRPCLatency !== -1
                            ? "rpc-status-high"
                            : customRPCLatency <= 400 && customRPCLatency !== -1
                            ? "rpc-status-medium"
                            : "rpc-status-low"
                        }`}
                      >
                        ({customRPCLatency ? commify(customRPCLatency < 0 ? 10000 : customRPCLatency) : "..."}ms)
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <ToastContainer
        limit={1}
        transition={Zoom}
        position="bottom-right"
        autoClose={7000}
        // hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
        theme={isLightTheme ? "light" : "dark"}
      />
      <EventToastContainer />
      <RedirectPopupModal
        redirectModalVisible={redirectModalVisible}
        setRedirectModalVisible={setRedirectModalVisible}
        appRedirectUrl={appRedirectUrl}
        setRedirectPopupTimestamp={setRedirectPopupTimestamp}
        setShouldHideRedirectModal={setShouldHideRedirectModal}
        shouldHideRedirectModal={shouldHideRedirectModal}
      />
      <Modal
        className="Connect-wallet-modal-v2"
        isVisible={walletModalVisible}
        setIsVisible={setWalletModalVisible}
        label={`Connect Wallet`}
      >
        <div className="wallet-desc">
          Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase securely.
          Never share them with anyone.
        </div>
        <div className="buttons">
          {/* <button className="Wallet-btn MetaMask-btn" onClick={activateMetaMask}>
            <img src={metamaskImg} alt="MetaMask" />
            <div>
              <span>MetaMask</span>
            </div>
          </button>
          <button className="Wallet-btn CoinbaseWallet-btn" onClick={activateCoinBase}>
            <img src={coinbaseImg} alt="Coinbase Wallet" />
            <div>
              <span>Coinbase Wallet</span>
            </div>
          </button>
          <button className="Wallet-btn WalletConnect-btn" onClick={activateWalletConnectV2}>
            <img src={walletConnectImg} alt="WalletConnect" />
            <div>
              <span>WalletConnect</span>
            </div>
          </button> */}
          {wallets.map((wallet) => {
            return (
              <button
                key={wallet.title}
                className={`Wallet-btn ${wallet.className}`}
                onClick={() => {
                  setSelectedWallet(wallet.title);
                  wallet.onClick();
                }}
              >
                <img width={36} height={36} src={wallet.logo} alt={wallet.title} />
                <div
                  style={{
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>{wallet.title}</span>
                  {selectedWallet === wallet.title && (
                    <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "12px" }}>
                      Please confirm in {wallet.title}
                    </div>
                  )}
                </div>
                {selectedWallet === wallet.title && <CircleLoader />}
              </button>
            );
          })}
        </div>
        <ExternalLink
          href="https://docs.mummy.finance/trading#adding-a-wallet"
          target="_blank"
          className="learn-to-connect"
        >
          Learn how to Connect Wallet <img src={isLightTheme ? icLinkLight : icLink} alt="icLink" />
        </ExternalLink>
      </Modal>
      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label={`Settings`}
      >
        <div className="settings-row-container">
          <div className="setting-title">Allowed Slippage</div>
          <div className="allowed-slippage">
            {DEFAULT_LIST_SLIPPAGE.map((item) => (
              <button
                className={`slippage-tab ${item === Number(slippageAmount) ? "slippage-tab--active" : ""}`}
                key={item}
                disabled={item === Number(slippageAmount)}
                onClick={() => setSlippageAmount(item)}
              >
                {item}%
              </button>
            ))}
            <div className="App-slippage-tolerance-input-container">
              <input
                type="number"
                className="App-slippage-tolerance-input"
                min="0"
                placeholder="Custom"
                value={slippageAmount}
                onChange={(e) => setSlippageAmount(e.target.value)}
              />
              <div className="App-slippage-tolerance-input-percent">%</div>
            </div>
          </div>
        </div>
        <div className="settings-row-container" style={{ marginTop: "24px" }}>
          <div className="setting-title">
            Swap Liquidity Resources ({(temptLiqSource?.split(",")?.length || 1) - 1})
          </div>
          <div className="liquidity-source-container">
            <div className="liquidity-source-list">
              {MICROSWAP_DEXES[MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) ? chainId : FANTOM].map(
                (item, index) => (
                  <div className="dex-container" key={item.id}>
                    <Checkbox
                      isChecked={temptLiqSource.includes(`${item.id},`)}
                      setIsChecked={() => handleChooseLiqSource(item.id)}
                    ></Checkbox>
                    <img alt={item.id} src={item.icon} className="token-img" />
                    <div className="name">{item.name}</div>
                  </div>
                )
              )}
            </div>
            <button className="border-btn" onClick={() => setTemptLiqSource("")}>
              Reset selection
            </button>
          </div>
        </div>
        <div className="settings-row-container" style={{ marginTop: "24px", marginBottom: "24px" }}>
          <div className="setting-title">More settings</div>
          <div className="Exchange-settings-row">
            <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
              <span>Display PnL after fees (V1)</span>
            </Checkbox>
          </div>
          <div className="Exchange-settings-row" style={{ marginTop: "4px" }}>
            <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
              <span>Include PnL in leverage display (V1)</span>
            </Checkbox>
          </div>
          {isDevelopment() && (
            <div className="Exchange-settings-row" style={{ marginTop: "4px" }}>
              <Checkbox
                isChecked={shouldDisableValidationForTesting}
                setIsChecked={setShouldDisableValidationForTesting}
              >
                <span>Disable order validations (V1)</span>
              </Checkbox>
            </div>
          )}
        </div>

        <button className="default-btn Exchange-swap-button" onClick={saveAndCloseSettings}>
          <span>Save</span>
        </button>
      </Modal>
    </>
  );
}

function App() {
  useScrollToTop();
  useEffect(() => {
    const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale;
    dynamicActivate(defaultLanguage);
  }, []);
  return (
    <SWRConfig value={{ refreshInterval: 10000 }}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <WebsocketContextProvider>
          <SEO>
            <Router>
              <I18nProvider i18n={i18n}>
                <ThemeProvider>
                  <FullApp />
                </ThemeProvider>
              </I18nProvider>
            </Router>
          </SEO>
        </WebsocketContextProvider>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

// const Snowflakes = styled.div`
//   @-webkit-keyframes snowflakes-fall {
//     0% {
//       top: -10%;
//     }
//     100% {
//       top: 100%;
//     }
//   }
//   @-webkit-keyframes snowflakes-shake {
//     0%,
//     100% {
//       -webkit-transform: translateX(0);
//       transform: translateX(0);
//     }
//     50% {
//       -webkit-transform: translateX(80px);
//       transform: translateX(80px);
//     }
//   }
//   @keyframes snowflakes-fall {
//     0% {
//       top: -10%;
//     }
//     100% {
//       top: 100%;
//     }
//   }
//   @keyframes snowflakes-shake {
//     0%,
//     100% {
//       transform: translateX(0);
//     }
//     50% {
//       transform: translateX(80px);
//     }
//   }
//   .snowflake {
//     color: #fff;
//     font-size: 1em;
//     font-family: Arial, sans-serif;
//     text-shadow: 0 0 5px #000;
//     position: fixed;
//     top: -10%;
//     z-index: 9999;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     cursor: default;
//     -webkit-animation-name: snowflakes-fall, snowflakes-shake;
//     -webkit-animation-duration: 10s, 3s;
//     -webkit-animation-timing-function: linear, ease-in-out;
//     -webkit-animation-iteration-count: infinite, infinite;
//     -webkit-animation-play-state: running, running;
//     animation-name: snowflakes-fall, snowflakes-shake;
//     animation-duration: 10s, 3s;
//     animation-timing-function: linear, ease-in-out;
//     animation-iteration-count: infinite, infinite;
//     animation-play-state: running, running;
//   }
//   .snowflake:nth-of-type(0) {
//     left: 1%;
//     -webkit-animation-delay: 0s, 0s;
//     animation-delay: 0s, 0s;
//   }
//   .snowflake:nth-of-type(1) {
//     left: 10%;
//     -webkit-animation-delay: 1s, 1s;
//     animation-delay: 1s, 1s;
//   }
//   .snowflake:nth-of-type(2) {
//     left: 20%;
//     -webkit-animation-delay: 6s, 0.5s;
//     animation-delay: 6s, 0.5s;
//   }
//   .snowflake:nth-of-type(3) {
//     left: 30%;
//     -webkit-animation-delay: 4s, 2s;
//     animation-delay: 4s, 2s;
//   }
//   .snowflake:nth-of-type(4) {
//     left: 40%;
//     -webkit-animation-delay: 2s, 2s;
//     animation-delay: 2s, 2s;
//   }
//   .snowflake:nth-of-type(5) {
//     left: 50%;
//     -webkit-animation-delay: 8s, 3s;
//     animation-delay: 8s, 3s;
//   }
//   .snowflake:nth-of-type(6) {
//     left: 60%;
//     -webkit-animation-delay: 6s, 2s;
//     animation-delay: 6s, 2s;
//   }
//   .snowflake:nth-of-type(7) {
//     left: 70%;
//     -webkit-animation-delay: 2.5s, 1s;
//     animation-delay: 2.5s, 1s;
//   }
//   .snowflake:nth-of-type(8) {
//     left: 80%;
//     -webkit-animation-delay: 1s, 0s;
//     animation-delay: 1s, 0s;
//   }
//   .snowflake:nth-of-type(9) {
//     left: 90%;
//     -webkit-animation-delay: 3s, 1.5s;
//     animation-delay: 3s, 1.5s;
//   }
//   .snowflake:nth-of-type(10) {
//     left: 25%;
//     -webkit-animation-delay: 2s, 0s;
//     animation-delay: 2s, 0s;
//   }
//   .snowflake:nth-of-type(11) {
//     left: 65%;
//     -webkit-animation-delay: 4s, 2.5s;
//     animation-delay: 4s, 2.5s;
//   }
// `;

const Notice = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .Modal-close-icon {
    min-width: 20px;
    position: absolute;
    right: 16px;
    top: 8px;
  }
  .noti-container {
    display: flex;
    align-items: center;
    .noti-title {
      font-weight: 700;
    }
  }

  a {
    color: #231f20;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    text-decoration-line: underline;
  }
  padding: 10px 24px;

  gap: 10px;

  background: #ffdf76;
  color: rgba(35, 31, 32, 1);

  @media screen and (max-width: 1024px) {
    padding: 10px 16px;
    justify-content: flex-start;
    .noti-container {
      align-items: flex-start;
      flex-direction: column;
      .noti-title {
        margin-bottom: 8px;
      }
    }
  }
`;

export default App;
